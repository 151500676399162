import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import SEO from '../src/helpers/seo';
import Buttons from '../src/components/Buttons/Buttons';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import tt from '../src/helpers/translation';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Loki from '../src/components/Loki/lokipane';

import './laliga.scss';

class Laliga extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allLaligaJumboText.edges[0].node.title,
      subtitle: this.props.data.allLaligaJumboText.edges[0].node.subtitle,
      image: {
        url: this.props.data.allLaligaJumboImage.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allLaligaJumboImage.edges[0].node.alt,
      },
      imageMobile: {
        alt: this.props.data.allLaligaMobileHeaderImage.edges[0].node.alt,
        url: this.props.data.allLaligaMobileHeaderImage.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };

    const lokiData = {
      title: tt('GALERIA DE FOTOS', this.props.pageContext.locale),
      arrayImg: this.props.data.allLaligaLokiBlock.nodes
        .map((block) => ({
          img: block.localImage ? block.localImage.childImageSharp.fluid : null,
          altImage: block.image_alt,
        }))
        .filter((item) => item.img !== null),

      bigArrayImg: this.props.data.allLaligaLokiBlock.nodes
        .map((block) => ({
          img: block.localImage ? block.localImage.childImageSharp.fluid : null,
          altImage: block.image_alt,
        }))
        .filter((item) => item.img !== null),
    };
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allLaligaSeoBlock.edges[0].node._0.title}
          description={this.props.data.allLaligaSeoBlock.edges[0].node._1.description}
          // imageRich={this.props.data.allFichaPromosRichDataApp.edges[0].node.rich_markup.imgRich}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="laliga-content">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allLaligaBreadcrumbBlock.edges[0].node.name}
              />
              <div className="big-image-laliga">
                <Img
                  fluid={
                    this.props.data.allLaligaBigImage.nodes[0].localImage.childImageSharp.fluid
                  }
                  alt={this.props.data.allLaligaBigImage.nodes[0].alt}
                />
              </div>
              <H3 titleAlone={this.props.data.allLaligaBigText.edges[0].node.title} />
              <div
                className="general-container"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.allLaligaBigText.edges[0].node.body,
                }}
              />
              <Buttons
                link={this.props.data.allLaligaButtonText.edges[0].node.cta}
                size="alone"
                color="orange"
                text={this.props.data.allLaligaButtonText.edges[0].node.cta_text}
              />
              <div className="laLigaImage">
                <iframe
                  title="laLiga"
                  className="laLigaVideo"
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/elzx44wPMGg"
                />
              </div>
              <H4 title={lokiData.title} />
              <Loki data={lokiData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

Laliga.propTypes = {};

export default Laliga;

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query QueryLaliga($locale: String!) {
    allLaligaJumboText(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          subtitle
        }
      }
    }
    allLaligaSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allLaligaBreadcrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allLaligaBigText(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
        }
      }
    }
    allLaligaJumboImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allLaligaBigImage(filter: { lang: { eq: $locale } }) {
      nodes {
        localImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        alt
      }
    }
    allLaligaMobileHeaderImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allLaligaButtonText(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          cta
          cta_text
        }
      }
    }
    allLaligaLokiBlock(filter: { lang: { eq: $locale } }, sort: { fields: position, order: ASC }) {
      nodes {
        localImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_alt
      }
    }
  }
`;
